import type { ParseUserAgentReturn } from './types'

export function parseUserAgent(): ParseUserAgentReturn {
  const detectedAgent: ParseUserAgentReturn = {
    os: 'unknown',
    browser: 'unknown',
  }

  if (!globalThis.navigator) return detectedAgent

  const { userAgent } = globalThis.navigator

  switch (true) {
    case /iPhone|iPad|iPod/.test(userAgent):
      detectedAgent.os = 'ios'
      if (/CriOS/.test(userAgent)) {
        detectedAgent.browser = 'google-chrome'
      } else if (/Safari/.test(userAgent)) {
        detectedAgent.browser =
          /AppleWebKit/.test(userAgent) && !/CriOS/.test(userAgent)
            ? 'safari-webview'
            : 'safari'
      }
      break

    case /Android/.test(userAgent):
      detectedAgent.os = 'android'
      if (/SamsungBrowser/.test(userAgent)) {
        detectedAgent.browser = 'samsung-internet'
      } else if (/Chrome/.test(userAgent)) {
        detectedAgent.browser = 'google-chrome'
      } else if (/wv/.test(userAgent)) {
        detectedAgent.browser = 'android-webview'
      }
      break

    case /Windows/.test(userAgent):
      detectedAgent.os = 'windows'
      if (/Chrome/.test(userAgent)) {
        detectedAgent.browser = 'google-chrome'
      }
      break

    case /Macintosh/.test(userAgent):
      detectedAgent.os = 'macos'
      if (/Chrome/.test(userAgent)) {
        detectedAgent.browser = 'google-chrome'
      } else if (/Safari/.test(userAgent)) {
        detectedAgent.browser = 'safari'
      }
      break
  }

  return detectedAgent
}
