import {
  type ParseUserAgentReturn,
  type UserAgentOS,
  type UserAgentBrowsers,
} from '@st/utils/lib/parseUserAgent/types'
import { parseUserAgent } from '@st/utils'

export function usePwaInstall() {
  type actionType = 'install' | 'modal' | 'landing'

  const { os, browser }: ParseUserAgentReturn = parseUserAgent()
  const { $pwa } = useNuxtApp()
  const router = useRouter()

  const pwaConfig: Record<
    UserAgentOS,
    Record<UserAgentBrowsers, actionType>
  > = {
    windows: {
      'google-chrome': 'install',
      safari: 'landing',
      'safari-webview': 'landing',
      'samsung-internet': 'landing',
      'android-webview': 'landing',
      unknown: 'landing',
    },
    macos: {
      'google-chrome': 'install',
      safari: 'modal',
      'safari-webview': 'modal',
      'samsung-internet': 'landing',
      'android-webview': 'landing',
      unknown: 'landing',
    },
    android: {
      'google-chrome': 'modal',
      'samsung-internet': 'modal',
      safari: 'landing',
      'safari-webview': 'landing',
      'android-webview': 'landing',
      unknown: 'landing',
    },
    ios: {
      'google-chrome': 'modal',
      safari: 'landing',
      'safari-webview': 'landing',
      'samsung-internet': 'landing',
      'android-webview': 'landing',
      unknown: 'landing',
    },
    unknown: {
      'google-chrome': 'landing',
      safari: 'landing',
      'safari-webview': 'landing',
      'samsung-internet': 'landing',
      'android-webview': 'landing',
      unknown: 'landing',
    },
  }

  async function installPwa(): Promise<void> {
    const osConfig = pwaConfig[os] ?? pwaConfig.unknown
    const action: actionType = osConfig[browser] ?? osConfig.unknown

    switch (action) {
      case 'install':
        await $pwa?.install()
        break
      case 'modal':
        router.replace({
          query: { modal: 'pwaInstall', browser },
        })
        break
      default:
        router.push('/pwa')
        break
    }
  }

  return {
    installPwa,
  }
}
